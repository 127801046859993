import ApiService from "@/common/api.service";

export default {
  register(data) {
    return ApiService.post("/register", data);
  },
  login(data) {
    return ApiService.post("/login", data);
  },
  ivyWayLogin(data) {
    return ApiService.post("ivy-way/login/token", data);
  },
  getProfile() {
    return ApiService.query("/me");
  },
  getProfileById(id) {
    return ApiService.query(`users/${id}`);
  },
  getUsersActivities(data) {
    return ApiService.query("/activity-logs", data);
  },
  getUserPerformance(userId, data) {
    return ApiService.query(`/users/${userId}/question-tag-stats`, data);
  },
  getUserPlans(userId, data) {
    return ApiService.query(`users/${userId}/subscriptions`, data);
  },
  getUserProfileById(id) {
    return ApiService.query(`teacher/users/${id}`);
  },
  getUserResults(role, id, data) {
    return ApiService.query(`${role}/users/${id}/user-exams`, data);
  },
  saveRoles(id, data) {
    return ApiService.patch(`admin/users/${id}/roles`, data);
  },
  getClasses(data) {
    return ApiService.query("session-class/list", data);
  },
  logout() {
    return ApiService.post("logout");
  },
  checkUsernameIsDuplicate(data) {
    return ApiService.post("auth/check-username", data);
  },
  searchStudents(data) {
    return ApiService.query("auth/students", data);
  },
  searchParents(data) {
    return ApiService.query("parent/search", data);
  },
  getStudentByParentId(parentId) {
    return ApiService.query(`parent/${parentId}/children`);
  },
  saveLang(data) {
    return ApiService.patch("lang", data);
  },
  // verifyEmail(data) {
  //   return ApiService.query("/verify", data);
  // },
  verifyEmail(id, hash) {
    return ApiService.post(`auth/email/verify/${id}/${hash}`);
  },
  getReferralCode() {
    return ApiService.query("/referral-code");
  },
  checkReferralCode(data) {
    return ApiService.post("/referral-code/check", data);
  },
  getReferralUsed() {
    return ApiService.query("/referral-code/used ");
  },
  sendVerifyEmail(data) {
    return ApiService.post("auth/email/request-verify", data);
  },
  exchangePoint() {
    return ApiService.post("/referral-code/exchange-point");
  },
  deleteUser(user_id) {
    return ApiService.delete("account/destroy/" + user_id);
  },
  getUsers(data) {
    return ApiService.query("admin/user", data);
  },
  getUsersLogs(data) {
    return ApiService.query("activity-log/history", data);
  },
  updateUserProExpiredDate(userId, expiredDate) {
    return ApiService.patch(`admin/user/${userId}/pro`, {
      taken_unlimited_expire_at: expiredDate
    });
  },
  giveTCoin(userId, tCoin) {
    return ApiService.post(`admin/user/${userId}/give-point`, {
      point: tCoin
    });
  },
  deductTCoin(userId, tCoin) {
    return ApiService.post(`admin/user/${userId}/deduct-point`, {
      point: tCoin
    });
  },
  removePro(userId) {
    return ApiService.delete(`admin/user/${userId}/pro`);
  },
  AdminRemoveUser(userId) {
    return ApiService.delete(`admin/users/${userId}`);
  },
  CompanyRemoveUser(userId) {
    return ApiService.delete(`company/users/${userId}`);
  }
};
