import {
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Select,
  OptionGroup,
  Option,
  Switch,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Slider,
  Row,
  Col,
  Upload,
  Badge,
  Card,
  Container,
  Header,
  Main,
  Link,
  Divider,
  Image,
  Avatar,
  Backtop,
  Icon,
  Loading,
  MessageBox,
  Message,
  Notification,
  Drawer,
  DatePicker,
  Collapse,
  CollapseItem,
  TimePicker,
  TimeSelect,
  Empty,
  Timeline,
  TimelineItem,
  Progress,
  Steps,
  Step,
  PageHeader,
  Rate,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem
} from "element-ui";

Popover.props.visibleArrow.default = false;
Tooltip.props.visibleArrow = false;
console.log(Popover);
console.log(Tooltip);
const components = [
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Select,
  OptionGroup,
  Option,
  Switch,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Slider,
  Row,
  Col,
  Upload,
  Badge,
  Card,
  Container,
  Header,
  Main,
  Link,
  Divider,
  Drawer,
  Image,
  Avatar,
  Backtop,
  Icon,
  DatePicker,
  Collapse,
  CollapseItem,
  TimePicker,
  TimeSelect,
  Empty,
  Timeline,
  TimelineItem,
  Progress,
  Steps,
  Step,
  PageHeader,
  Rate,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
  Loading.directive
];

const element = {
  install: function(Vue) {
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;

    components.forEach(component => {
      Vue.use(component);
    });
  }
};

export default element;
