import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import User from "@/apis/user.js";
import store from "@/store";
import roleMixin from "@/mixins/role.js";
import { MessageBox } from "element-ui";
import i18n from "@/i18n";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";
import { replace } from "lodash";

Vue.use(VueRouter);

const routes = [
  // =====  登录  =====
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: "/password/forgot",
    name: "ForgetPassword",
    component: () => import("@/views/login/ForgetPassword.vue")
  },
  {
    path: "/password/set",
    name: "SetPassword",
    component: () => import("@/views/login/SetPassword.vue")
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/login/ResetPassword.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/Terms.vue")
  },
  // =====  登录  =====

  // =====  首页  =====
  {
    path: "/",
    name: "LandingPage",
    component: () => import("@/views/landingPage/Index.vue")
    // redirect: { name: "LandingPageStudent" }
  },
  // =====  首页  =====

  //学生的功能
  {
    path: "/student",
    name: "Student",
    component: () => import("@/views/pageStudents/Index.vue"),
    redirect: {
      name: "StudentHome"
    },
    children: [
      {
        path: "home",
        name: "LandingPageStudents",
        component: () => import("@/views/landingPage/Students.vue"),
        meta: {
          role: "student"
        }
      },
      {
        path: "dashboard",
        name: "StudentHome",
        component: () => import("@/views/home/Student.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "student"
        }
      },
      {
        path: "plans",
        name: "StudentPlans",
        component: () => import("@/views/plans/StudentPage.vue"),
        meta: {
          role: "student"
        }
      },
      {
        path: "about",
        name: "StudentAbout",
        component: () => import("@/views/landingPage/About.vue"),
        meta: {
          role: "student"
        }
      },
      {
        path: "contact",
        name: "StudentContact",
        component: () => import("@/views/landingPage/Contact.vue"),
        meta: {
          role: "student"
        }
      },
      {
        path: "classes",
        name: "StudentClasses",
        component: () => import("@/views/courses/Page.vue"),
        redirect: {
          name: "StudentClassesList"
        },
        meta: {
          role: "student"
        },
        children: [
          {
            path: "list",
            name: "StudentClassesList",
            component: () => import("@/views/courses/list/Student.vue"),
            meta: {
              requiresAuth: true,
              role: "student"
            }
          },
          {
            path: "join",
            name: "JoinCourse",
            component: () => import("@/views/courses/student/Join.vue"),
            meta: {
              requiresAuth: true,
              role: "student"
            }
          },
          {
            path: ":id",
            name: "StudentClassDetail",
            component: () => import("@/views/courses/detail/Student.vue"),
            redirect: {
              name: "StudentClassInfo"
            },
            children: [
              {
                path: "info",
                name: "StudentClassInfo",
                component: () => import("@/views/courses/info/Student.vue"),
                meta: {
                  requiresAuth: true,
                  role: "student"
                }
              },
              // {
              //   path: "roster",
              //   name: "StudentClassRoster",
              //   component: () => import("@/views/courses/roster/Index.vue"),
              //   meta: {
              //     requiresAuth: true,
              //     role: "student"
              //   }
              // },
              {
                path: "materials",
                name: "StudentClassMaterials",
                component: () => import("@/views/courses/materials/Index.vue"),
                meta: {
                  requiresAuth: true,
                  role: "student"
                }
              },
              {
                path: "tests",
                name: "StudentClassTests",
                component: () => import("@/views/courses/tests/Student.vue"),
                meta: {
                  requiresAuth: true,
                  role: "student"
                }
              }
            ]
          }
        ]
      },
      {
        path: "profile",
        name: "StudentProfile",
        component: () => import("@/views/profile/Student.vue"),
        meta: {
          requiresAuth: true,
          role: "student"
        }
      }
    ]
  },
  //超级管理员的功能
  {
    path: "/super-admin",
    name: "Admin",
    component: () => import("@/views/pageStudents/Index.vue"),
    redirect: {
      name: "SuperAdminHome"
    },
    children: [
      {
        path: "dashboard",
        name: "SuperAdminHome",
        component: () => import("@/views/home/SuperAdmin.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "super_admin"
        }
      },
      {
        path: "profile",
        name: "AdminProfile",
        component: () => import("@/views/profile/Admin.vue")
      },
      {
        path: "companies",
        name: "AdminViewCompanies",
        component: () => import("@/views/company/list/Admin.vue"),
        meta: {
          showFooter: false,
          role: "super_admin"
        }
      },
      {
        path: "users",
        name: "AdminViewUsers",
        component: () => import("@/views/users/SuperAdmin.vue"),
        meta: {
          showFooter: false,
          role: "super_admin"
        }
      }
    ]
  },
  // 查看其他用户信息
  {
    path: "/user/:uid",
    name: "SuperAdminViewUser",
    component: () => import("@/views/profile/AdminViewUser.vue"),
    meta: {
      requiresAuth: true,
      showFooter: false
    }
  },
  //老师的功能
  {
    path: "/educator",
    name: "Educator",
    component: () => import("@/views/pageEducators/Index.vue"),
    redirect: {
      name: "EducatorHome"
    },
    meta: {
      role: "educator"
    },
    children: [
      {
        path: "act/result/:userExamId",
        name: "EducatorACTResultPage",
        component: () => import("@/views/act/actResult/ActResult.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true,
          role: "educator"
        }
      },
      {
        path: "users",
        name: "EducatorViewUsers",
        component: () => import("@/views/users/EducatorUser.vue"),
        meta: {
          showFooter: false,
          role: "educator"
        }
      },
      {
        path: "act/explanations/:examId/:userExamId",
        name: "EducatorACTExplanations",
        component: () => import("@/views/act/actResult/Explanation.vue"),
        meta: {
          requiresAuth: true,
          role: "educator"
        }
      },
      {
        path: "sat/result/:userExamId",
        name: "EducatorSATResultPage",
        component: () => import("@/views/sat/satResult/SatResult.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true,
          role: "educator"
        }
      },
      {
        path: "sat/explanations/:examId/:userExamId",
        name: "EducatorSATExplanations",
        component: () => import("@/views/sat/satResult/Explanation.vue"),
        meta: {
          requiresAuth: true,
          role: "educator"
        }
      },
      {
        path: "home",
        name: "LandingPageEducators",
        component: () => import("@/views/landingPage/Educators.vue"),
        meta: {
          role: "educator"
        }
      },
      {
        path: "about",
        name: "EducatorAbout",
        component: () => import("@/views/landingPage/About.vue"),
        meta: {
          role: "educator"
        }
      },
      {
        path: "contact",
        name: "EducatorContact",
        component: () => import("@/views/landingPage/Contact.vue"),
        meta: {
          role: "educator"
        }
      },
      {
        path: "dashboard",
        name: "EducatorHome",
        component: () => import("@/views/home/Educator.vue"),
        meta: {
          requiresAuth: true,
          role: "educator"
        }
      },
      {
        path: "plans",
        name: "EducatorPlans",
        component: () => import("@/views/plans/EducatorPage.vue"),
        meta: {
          role: "educator"
        }
      },
      // EducatorProfile
      {
        path: "profile",
        name: "EducatorProfile",
        component: () => import("@/views/profile/Educator.vue"),
        meta: {
          role: "educator"
        }
      },
      {
        path: "students",
        name: "EducatorStudents",
        component: () => import("@/views/users/Educator.vue"),
        meta: {
          showFooter: false,
          role: "educator"
        }
      },
      {
        path: "plan-coins",
        name: "EducatorPlanCoins",
        component: () => import("@/views/planCoins/Educator.vue"),
        meta: {
          showFooter: false,
          role: "educator"
        }
      }
    ]
  },
  // Classes
  {
    path: "/classes",
    name: "EducatorClasses",
    component: () => import("@/views/courses/Page.vue"),
    redirect: {
      name: "EducatorClassesList"
    },
    meta: {
    },
    children: [
      {
        path: "list",
        name: "EducatorClassesList",
        component: () => import("@/views/courses/list/Educator.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      },
      {
        path: "add",
        name: "EducatorClassesAdd",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      },
      {
        path: ":id/edit",
        name: "EducatorClassesEdit",
        component: () => import("@/views/courses/Edit.vue"),
        redirect: {
          name: "EducatorClassInfoEdit",
          role: "educator"
        },
        meta: {
          requiresAuth: true,
          showFooter: false
        },
        children: [
          {
            path: "info",
            name: "EducatorClassInfoEdit",
            component: () => import("@/views/courses/info/Edit.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          },
          {
            path: "roster",
            name: "EducatorClassRosterEdit",
            component: () => import("@/views/courses/roster/Edit.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          },
          {
            path: "materials",
            name: "EducatorClassMaterialsEdit",
            component: () => import("@/views/courses/materials/Edit.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          }
        ]
      },
      {
        path: ":id",
        name: "TeacherCourseDetail",
        component: () => import("@/views/courses/detail/Educator.vue"),
        redirect: {
          name: "EducatorClassInfo"
        },
        meta: {
          requiresAuth: true,
          showFooter: false
        },
        children: [
          {
            path: "info",
            name: "EducatorClassInfo",
            component: () => import("@/views/courses/info/Index.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          },
          {
            path: "roster",
            name: "EducatorClassRoster",
            component: () => import("@/views/courses/roster/Index.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          },
          {
            path: "materials",
            name: "EducatorClassMaterials",
            component: () => import("@/views/courses/materials/Index.vue"),
            meta: {
              requiresAuth: true,
              showFooter: false
            }
          },
          {
            path: "tests",
            name: "EducatorClassTests",
            component: () => import("@/views/courses/tests/Educator.vue"),
            meta: {
              requiresAuth: true
            }
          }
        ]
      }
    ]
  },
  // Questions
  {
    path: "/questions",
    name: "EducatorQuestions",
    component: () => import("@/views/questions/Page.vue"),
    redirect: {
      name: "EducatorQuestionsList"
    },
    children: [
      {
        path: "list",
        name: "EducatorQuestionsList",
        component: () => import("@/views/questions/list/Educator.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      },
      {
        path: "add",
        name: "EducatorAddQuestion",
        component: () => import("@/views/questions/add/Educator.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      },
      {
        path: ":questionId",
        name: "EducatorQuestionDetail",
        component: () => import("@/views/questions/detail/Educator.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      },
      {
        path: ":questionId/edit",
        name: "EducatorQuestionEdit",
        component: () => import("@/views/questions/add/Educator.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false
        }
      }
    ]
  },
  //TestResults
  {
    path: "/testResults",
    name: "AllTestResults",
    component: () => import("@/views/testResults/Admin.vue")
  },
  {
    path: "/activities",
    name: "AdminViewUsersActivities",
    component: () => import("@/views/activities/Admin.vue")
  },
  // Modules
  {
    path: "/tests/:type",
    name: "EducatorModules",
    component: () => import("@/views/tests/modules/Page.vue"),
    redirect: {
      name: "EducatorModulesList"
    },
    children: [
      {
        path: "list",
        name: "EducatorModulesList",
        component: () => import("@/views/tests/modules/list/Educator.vue"),
        meta: {
        }
      },
      // EducatorModulesBrowse
      {
        path: "browse/:id",
        name: "EducatorModuleBrowse",
        component: () =>
          import("@/views/tests/modules/browse/Educator.vue"),
        meta: {
        }
      },
      {
        path: "pdf/:id",
        name: "EducatorModulePDF",
        component: () => import("@/views/tests/modules/browse/PDF.vue"),
        meta: {
        }
      }
    ]
  },
  // FullTests
  {
    path: "/full-tests",
    name: "EducatorFullTests",
    component: () => import("@/views/tests/fullTests/Page.vue"),
    redirect: {
      name: "EducatorFullTestsList"
    },
    meta: {
      role: "educator"
    },
    children: [
      {
        path: "add",
        name: "EducatorFullTestsAdd",
        component: () => import("@/views/tests/fullTests/add/Educator.vue"),
        meta: {}
      },
      {
        path: "list",
        name: "EducatorFullTestsList",
        component: () =>
          import("@/views/tests/fullTests/list/Educator.vue"),
        meta: {}
      },
      // EducatorModulesBrowse
      {
        path: "edit/:id",
        name: "EducatorFullTestEdit",
        component: () =>
          import("@/views/tests/fullTests/edit/Educator.vue"),
        meta: {}
      },
      // EducatorTestsBrowse
      {
        path: "browse/:id",
        name: "EducatorFullTestBrowse",
        component: () =>
          import("@/views/tests/fullTests/browse/Educator.vue"),
        meta: {}
      },
      {
        path: "pdf/:id",
        name: "EducatorFullTestPDF",
        component: () => import("@/views/tests/fullTests/browse/PDF.vue"),
        meta: {}
      }
    ]
  },

  //机构管理员
  {
    path: "/educator/company",
    name: "CompanyHome",
    component: () => import("@/views/company/Index.vue"),
    redirect: {
      name: "CompanyList"
    },
    meta: {
      showFooter: false,
      role: "educator"
    },
    children: [
      {
        path: "list",
        name: "CompanyList",
        component: () => import("@/views/company/list/Index.vue"),
        meta: {
          showFooter: false,
          role: "educator"
        }
      },
      {
        path: "detail/:id",
        component: () => import("@/views/company/detail/Index.vue"),
        children: [
          {
            path: "info",
            name: "CompanyInfo",
            component: () => import("@/views/company/info/Index.vue"),
            meta: {
              showFooter: false,
              role: "educator"
            }
          },
          {
            path: "classes",
            name: "CompanyClasses",
            component: () => import("@/views/company/classes/Index.vue"),
            meta: {
              showFooter: false,
              role: "educator"
            }
          },
          {
            path: "users",
            name: "CompanyUsers",
            component: () => import("@/views/users/Educator.vue"),
            meta: {
              showFooter: false,
              role: "educator"
            }
          },
          {
            path: "points",
            name: "CompanyPoints",
            component: () => import("@/views/planCoins/Educator.vue"),
            meta: {
              showFooter: false,
              role: "educator"
            }
          }
        ]
      }
    ]
  },
  // ===== ACT系统 ======
  {
    path: "/act",
    name: "ACTHome",
    component: () => import("@/views/act/Index.vue"),
    redirect: { name: "ACTList" },
    children: [
      {
        path: "list",
        name: "ACTList",
        component: () => import("@/views/act/List.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        }
      },
      {
        path: "history",
        name: "ACTHistory",
        component: () => import("@/views/act/History.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        },
        children: []
      }
    ]
  },
  {
    path: "/act/test/:examId/:userExamId",
    name: "ACTTest",
    component: () => import("@/views/act/actTest/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/act/result/:userExamId",
    name: "ACTResultPage",
    component: () => import("@/views/act/actResult/ActResult.vue"),
    meta: {
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/act/explanations/:examId/:userExamId",
    name: "ACTExplanations",
    component: () => import("@/views/act/actResult/Explanation.vue"),
    meta: {
      requiresAuth: true
    }
  },
  //===== SAT系统 ======
  {
    path: "/sat",
    name: "SATHome",
    component: () => import("@/views/sat/Index.vue"),
    redirect: { name: "SATList" },
    children: [
      {
        path: "list",
        name: "SATList",
        component: () => import("@/views/sat/List.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        }
      },
      {
        path: "history",
        name: "SATHistory",
        component: () => import("@/views/sat/History.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/sat/test/:examId/:userExamId",
    name: "SATTest",
    component: () => import("@/views/sat/satTest/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/sat/result/:userExamId",
    name: "SATResultPage",
    component: () => import("@/views/sat/satResult/SatResult.vue"),
    meta: {
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/sat/explanations/:examId/:userExamId",
    name: "SATExplanations",
    component: () => import("@/views/sat/satResult/Explanation.vue"),
    meta: {
      requiresAuth: true
    }
  },
  //生字卡
  {
    path: "/flashcards",
    name: "FlashCards",
    props: true,
    component: () => import("@/views/flashCards/FlashCards.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/blogs",
    name: "Blogs",
    beforeEnter() {
      location.href = "https://blog.ivy-way.com/";
    }
  },
  {
    path: "/flashcards/list/:title",
    name: "FlashCardsList",
    props: true,
    component: () => import("@/views/flashCards/List.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/flashcards/memorize/:title",
    name: "MemorizeList",
    props: true,
    component: () => import("@/views/flashCards/MemorizeList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  // TODO: 404
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue")
  },
  {
    path: "/403",
    name: "PageRole",
    component: () => import("@/views/PageRole.vue")
  },
  {
    path: "*",
    name: "RedirectToNotFound",
    component: () => import("@/views/RedirectToNotFound.vue")
  }
];

const theme = Theme.name;
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const ignoreRemeberPage = [
    "Login",
    "PageNotFound",
    "ForgetPassword",
    "SetPassword",
    "ResetPassword",
    "Verify"
  ];
  updateFavicon("name", Theme.icon, Theme.image);

  if (!ignoreRemeberPage.includes(to.name)) {
    localStorage.setItem("lastAccessRoutePath", to.fullPath);
  }

  if (to.meta.requiresAuth && !JwtService.hasToken()) {
    next({
      name: "Login",
      query: {
        role: to.meta && to.meta.role ? to.meta.role : "student"
      }
    });
  }

  let isAdmin = false;
  let isCompanyAdmin = false;
  let isTeacher = false;
  let isStudent = false;
  let user = null;
  if (JwtService.hasToken()) {
    // TODO：這個判斷會一直進入，因為 user 一直是 null
    if (!user) {
      const getProfile = function() {
        return ApiService.query("/me");
      };
      await getProfile()
        .then(profile => {
          user = profile.user;
          if (user.roles.length < 1) {
            isStudent = true;
          }
          user.roles.forEach(role => {
            if (role.name === "super_admin") {
              isAdmin = true;
            }
            if (role.name === "teacher") {
              isTeacher = true;
            }
            if (role.name === "organization_admin") {
              isCompanyAdmin = true;
            }
          });
        })
        .catch(res => {
          // 無效 Token 要清除，不然還會一直進入這裡
          JwtService.destroyToken();
          next({ name: "Login" });
        });
    }
    const nextRouter = function() {
      if (isTeacher) {
        next({ name: "EducatorHome", replace: true });
      } else if (isStudent) {
        next({ name: "StudentHome", replace: true });
      } else if (isAdmin) {
        next({ name: "SuperAdminHome", replace: true });
      } else {
        next({ name: "LandingPage", replace: true });
      }
    };

    const pagesWhichCanNotAccessAfterLogin = [
      "Login",
      "Register",
      "LandingPage"
    ];
    if (pagesWhichCanNotAccessAfterLogin.includes(to.name)) {
      nextRouter();
    }
    if (!isStudent && to.meta.role === "student") {
      nextRouter();
    }
    if (!isAdmin && to.meta.role === "super_admin") {
      nextRouter();
    }
    if (!isCompanyAdmin && to.meta.role === "company") {
      nextRouter();
    }
    if (!isTeacher && to.meta.role === "educator") {
      nextRouter();
    }
  }
  //最后检查此页面是否在编辑
  if (store.state.router.isEditing) {
    //页面中含有正在编辑内容
    MessageBox.confirm(
      "Your changes may not be saved. Are you sure you want to leave the page?",
      "Warning",
      {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }
    )
      .then(async () => {
        store.commit("router/SET_EDITING", false);
        next();
      })
      .catch(() => {
        next(false);
      });
  } else {
    next();
  }
});

const updateFavicon = (title, imgPath, image) => {
  const link = document.querySelector("link[rel*='icon']");
  link.rel = "icon";
  link.href = baseDomainPath + imgPath + "?" + new Date().getTime();

  const meta = document.querySelector("meta[property*='image']");
  meta.property = "og:image";
  meta.content = image;
};
router.onError(error => {
  const pattern = "Loading chunk";
  const isChunkLoadingFailed = error.message.includes(pattern);
  const cssPattern = "Loading CSS chunk";
  const isCSSChunkLoadingFailed = error.message.includes(cssPattern);
  if (isChunkLoadingFailed || isCSSChunkLoadingFailed) {
    MessageBox({
      title: i18n.t("message.notice"),
      message: `
        ${i18n.t(
    "message.chunkLoadingError1"
  )}<a target="_blank" href="https://www.messenger.com/t/333718851200334/?messaging_source=source%3Apages%3Amessage_shortlink">
        ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t(
  "message.chunkLoadingError3"
)}
      `,
      confirmButtonText: "Refresh",
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      dangerouslyUseHTMLString: true,
      callback: () => window.location.reload()
    });
  }
});

export default router;
