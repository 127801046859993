<template>
  <div>
    <el-tooltip v-show="false" effect="dark" :content="`AI`" placement="top">
      <span
        class="contact-help todo-button text-success text-center"
        @click="getShowAIChat(!showAIChat)"
      >
        <b class="logo-color">AI</b>
      </span>
    </el-tooltip>
    <div class="todo-box" v-show="showAIChat">
      <div class="todo-box-cover">
        <div class="todo">
          <i class="bart" @mousedown="dragTopStart"></i>
          <i class="barb" @mousedown="dragBottomStart"></i>
          <i class="barr" @mousedown="dragRightStart"></i>
          <i class="barl" @mousedown="dragLeftStart"></i>
          <i class="bar" @mousedown="dragBarStart"></i>
          <div class="chat-cover">
            <div class="AI-Header">
              <div class="header-logo" @mousedown="dragMove">
                <span>
                  <b class="logo-color logo-title">AI</b>
                  Tutor 🕵️‍♂️
                </span>
                <span class="close-i" @click="confirmCloseChat">
                  <i class="el-icon-close"></i>
                </span>
              </div>
            </div>
            <div class="AI-Content" ref="AIContent">
              <!-- <div class="tips-container d-flex">
                <div class="tip-text">
                  <div class="history-line history-line-before"></div>
                  <span>The above is historical information</span>
                  <div class="history-line history-line-after"></div>
                </div>
              </div> -->
              <div
                v-for="(item, index) in chatHeaderList"
                :key="'header-' + index"
              >
                <div
                  v-if="item.role === 'user'"
                  class="answer-markdown chat-item user-chat"
                  v-html="markdown.render(item.content)"
                ></div>
                <div v-if="item.role === 'assistant'" class="chat-item AI-chat">
                  <div
                    class="answer-markdown"
                    ref="answer-markdown"
                    v-html="markdown.render(item.content)"
                  ></div>
                </div>
              </div>
              <div v-for="(item, index) in chatList" :key="'chat-' + index">
                <div
                  v-if="item.role === 'user'"
                  class="answer-markdown chat-item user-chat"
                  v-html="markdown.render(item.content)"
                ></div>
                <div v-if="item.role === 'assistant'" class="chat-item AI-chat">
                  <div
                    class="answer-markdown"
                    ref="answer-markdown"
                    v-html="markdown.render(item.content)"
                  ></div>
                </div>
              </div>
              <div v-if="showMaxAlert">
                <el-alert
                  title="The current conversation has exceeded the limit. "
                  description="Please close the window and start a new one."
                  type="warning"
                  :closable="true"
                  show-icon
                  center
                  @close="showMaxAlert = false"
                  style="margin: 1rem auto 0; text-align: center;"
                />
              </div>
              <div v-if="waitingAiResponse" class="chat-item AI-chat">
                <div>Loading...</div>
              </div>
            </div>
            <div class="AI-Footer">
              <div class="AI-Input">
                <div class="input-content">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 1 }"
                    v-model="myMessage"
                    placeholder="You can continue to ask me questions."
                    @keydown.enter.exact.prevent.native="sendMessages"
                    @keydown.shift.enter.native="() => {}"
                  >
                  </el-input>
                </div>
                <div class="input-button" @click="sendMessages">
                  <i class="fas fa-arrow-alt-circle-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Ckeditor5 from "@/components/ckeditor/ckeditor5.vue";
// import Todos from "@/apis/todos.js";
import { money, instant } from "@ivy-way/material";
import moment from "moment";
import formMixin from "@/mixins/form";
// import todoCard from "@/components/todo/todoCard";
import $ from "jquery";
import ACTList from "@/views/chatAI/apis/ChatAI.js";
import MarkdownIt from "markdown-it";

export default {
  components: {},

  mixins: [formMixin],

  props: [],
  data() {
    return {
      showStopButton: false,
      myMessage: "",
      // 不需要送出的 header
      chatHeaderList: [],
      chatList: [],
      interval: null,
      waitingAiResponse: false,
      usage_loaded: 0,
      showMaxAlert: false
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    ...mapState("AIChat", ["showAIChat", "lastString"]),
    markdown() {
      return new MarkdownIt();
    },
    instant() {
      return instant;
    },
    moment() {
      return moment;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    }
  },
  watch: {
    showAIChat(param) {
      if (param) {
        if (this.lastString.answer) {
          this.usage_loaded = 0;
          this.chatHeaderList.push({
            role: "assistant",
            content: this.lastString.answer,
            dateTime: "2024-09-19 14:30:02"
          });

          this.chatHeaderList.push({
            role: "assistant",
            content: "What question do you have?",
            dateTime: "2024-09-19 14:30:02"
          });
          this.initMathJaxPlugin(1);
          this.scrollToBottom(this.$refs.AIContent);
        }
      } else {
        this.myMessage = "";
        this.chatHeaderList = [];
        this.chatList = [];
      }
    },
    chatHeaderList: {
      deep: false,
      handler() {
        this.initMathJaxPlugin(1);
      }
    },
    chatList: {
      deep: false,
      handler() {
        this.initMathJaxPlugin(1);
      }
    }
  },

  mounted() {
    this.initMathJaxPlugin(1);
  },

  methods: {
    ...mapActions("AIChat", ["getShowAIChat"]),
    initMathJaxPlugin(v) {
      this.$nextTick(() => {
        let tag = this.$refs["answer-markdown"];
        if (this.$mathJaxSAT.isMathjaxConfig) {
          this.$mathJaxSAT.initMathjaxConfig();
        }
        console.log(tag);
        if (tag) {
          this.$mathJaxSAT.MathQueue([tag]);
        }
      });
    },
    confirmCloseChat() {
      this.$confirm(
        "Closing the chat will clear all content. Are you sure you want to close?",
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.getShowAIChat(false);
        })
        .catch(() => {});
    },
    handleInput(event) {
      let text = event.target.innerText;
      this.myMessage = text;
    },
    async sendMessages() {
      if(this.usage_loaded > 0.8) {
        this.showMaxAlert = true;
        this.scrollToBottom();
      } else {
        if (!this.waitingAiResponse && this.myMessage) {
          this.chatList.push({
            role: "user",
            content: this.myMessage,
            dateTime: "2024-09-19 14:30:02"
          });
          this.waitingAiResponse = true;
          this.scrollToBottom();
          this.myMessage = "";
          try {
            const res = await ACTList.postAIExplanation(
              this.lastString.questionId,
              {
                messages: this.chatList
              }
            );
            this.usage_loaded = res.usage_loaded;
            this.appendChatListWithTypeAnimation(res.content);
          } catch (err) {
            // TODO: 可能需要一些顯示
          } finally {
            this.waitingAiResponse = false;
          }
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.AIContent.scrollTop = this.$refs.AIContent.scrollHeight;
      });
    },
    appendChatListWithTypeAnimation(content, role = "assistant") {
      let iter = 0;
      this.chatList.push({
        role: role,
        content: "",
        dateTime: "2024-09-19 14:30:02"
      });

      this.interval = setInterval(() => {
        if (iter < content.length) {
          this.chatList[this.chatList.length - 1].content += content[iter++];
          this.scrollToBottom();
        } else {
          this.initMathJaxPlugin(2);
          clearInterval(this.interval);
        }
      }, 5);
    },
    dragLeftStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var drafw = $(".todo-box .todo").width();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX;
        if (mouseX < 0) mouseX = 0;
        $(".todo-box .todo").css({
          left: mouseX + "px",
          width: disX - mouseX - 4 + drafw + "px"
        });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragRightStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var drafw = $(".todo-box .todo").width();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX - disX,
          maxX = document.documentElement.clientWidth - disX - 2;
        if (mouseX > maxX) mouseX = maxX;
        $(".todo-box .todo").css({ width: mouseX + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragTopStart(event) {
      event = event || window.event;
      var disY = $(".todo-box .todo").offset().top;
      var drafH = $(".todo-box .todo").height();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseY = event.pageY;
        var range = disY - mouseY - 4;
        if (mouseY + 4 > 0) {
          $(".todo-box .todo").css({
            top: event.clientY + "px",
            height: range + drafH + "px"
          });
        }
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragBottomStart(event) {
      event = event || window.event;
      var disY = $(".todo-box .todo").offset().top;
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseY = event.pageY - disY,
          maxY = document.documentElement.clientHeight - disY - 2;
        // if (mouseY > maxY) mouseY = maxY;
        $(".todo-box .todo").css({ height: mouseY + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragBarStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var disY = $(".todo-box .todo").offset().top;
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX - disX + 14,
          mouseY = event.pageY - disY + 14,
          maxX = document.documentElement.clientWidth - disX - 2,
          maxY = document.documentElement.clientHeight - disY - 2;
        // if (mouseX > maxX) mouseX = maxX;
        // if (mouseY > maxY) mouseY = maxY;
        $(".todo-box .todo").css({
          width: mouseX + "px",
          height: mouseY + "px"
        });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragMove(event) {
      event = event || window.event;
      var disX = event.pageX - $(".todo-box .todo").offset().left;
      var disY = event.pageY - $(".todo-box .todo").offset().top;
      console.log(disX, disY);
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.clientX - disX;
        var mouseY = event.clientY - disY;

        console.log(event.pageX, event.pageY);
        var maxX =
            document.documentElement.clientWidth -
            $(".todo-box .todo").outerWidth(),
          maxY =
            document.documentElement.clientHeight -
            $(".todo-box .todo").outerHeight();
        if (mouseX < 0) {
          mouseX = 0;
        } else if (mouseX > maxX) {
          mouseX = maxX;
        }
        if (mouseY < 0) {
          mouseY = 0;
        } else if (mouseY > maxY) {
          mouseY = maxY;
        }
        $(".todo-box .todo").css({ left: mouseX + "px", top: mouseY + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    }
  }
};
</script>

<style scoped>
.contact-help {
  right: 40px;
  bottom: 220px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #42a16a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}

::v-deep .el-badge__content {
  line-height: 16px;
}
::v-deep .el-badge__content.is-fixed {
  right: 25px;
  top: 5px;
}
.todo > i {
  position: absolute;
  z-index: 2;
}
.todo i.bart,
.todo i.barb {
  height: 4px;
  left: -1px;
  right: -1px;
  cursor: n-resize;
}
.todo i.bart {
  top: -1px;
}
.todo i.barb {
  bottom: -1px;
}
.todo i.barr,
.todo i.barl {
  width: 8px;
  top: -1px;
  bottom: -1px;
  cursor: e-resize;
  /*background:#ffc;
	*/
}
.todo i.barl {
  left: -1px;
}
.todo i.barr {
  right: -1px;
}
.todo i.bar {
  width: 14px;
  height: 14px;
  cursor: nw-resize;
  bottom: -1px;
  right: -1px;
}
.todo-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
}
.todo-box-cover {
  width: 100%;
  height: 100%;
  position: relative;
}
.todo {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 65%;
  background-color: #f5f6f9;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.todo * {
  scrollbar-color: hsla(228, 7%, 74%, 0.6) transparent;
  scrollbar-width: thin;
}
.todo-cover {
  display: flex;
  width: 100%;
  height: 100%;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 1px solid #ebeef5;
  /* transition: width 0.3s; */
  width: 200px;
  user-select: none;
  height: 100%;
  overflow: auto;
}
.left.left-hide {
  width: 60px;
  text-align: center;
}
.logo {
  color: var(--themeColor);
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 1rem 0;
  padding: 0 1rem;
}

.item {
  height: 50px;
  line-height: 50px;
  padding: 0 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.item:hover {
  opacity: 0.8;
  color: var(--themeColor);
  /* font-size: 0.8rem; */
}
.item.active {
  background-color: var(--themeColor);
  color: white;
}
.right {
  flex: 1;
  /* min-width: 500px; */
  background-color: #e3e3e3;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  height: 50px;
  line-height: 50px;
  background-color: white;
  cursor: move;
  text-align: right;
  padding: 0 1rem;
  font-weight: 700;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
}
.close-i {
  cursor: pointer;
}
.right-content {
  width: 100%;
  /* padding: 1rem; */
  /* height: 650px; */
  overflow: auto;
  flex: 1;
}
.right-content-content {
  /* height: 600px; */
  overflow: auto;
  padding: 1rem;
}
.right-footer {
  padding-right: 1rem;
  text-align: right;
  height: 50px;
  line-height: 50px;
  background-color: white;
  font-weight: 700;
  /* border-top: 1px solid #ebeef5; */
}
</style>
<style scoped>
.todo-item {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid #ebeef5;
  cursor: pointer;
  background-color: #f5f6f9;
}
.todo-item:hover,
.todo-item-active {
  border: 2px solid var(--themeColor);
}
.todo-item-col {
  display: flex;
  align-items: center;
}
::v-deep .todo-item .el-form-item {
  margin: 0;
}
.todo-date {
  width: 120px;
  color: #989898;
  display: flex;
  align-items: center;
}
.todo-title {
  flex: 1;
  overflow: hidden;
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todo-users {
  width: 50px;
  font-weight: 500;
}
.todo-actions {
  width: 40px;
}
.todo-check {
  width: 30px;
}
@media screen and (max-width: 768px) {
  .todo {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
<style scoped>
.chat-cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.todo {
  border-radius: 1rem 1rem 1rem 1rem;
}
/* .AI-Header {
  display: flex;
} */
.logo-color {
  width: 100%;
  text-align: center;
  color: var(--themeColor);
  white-space: nowrap;
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 25.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  font-size: 1.5rem;
  border-radius: 1rem 1rem 0 0;
  font-weight: 700;
  padding: 1rem;
  background: linear-gradient(hsla(0, 0%, 100%, 0), #f5f6f9) padding-box,
    linear-gradient(180deg, #a0e2ff, #f5f6f9) border-box;
}
.close-i {
  font-size: 1rem;
  cursor: pointer;
}
.tips-container {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 0.8rem;
}
.tip-text {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
}
.history-line {
  height: 0.05rem;
  width: 4rem;
}
.history-line-before {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.1)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.1), transparent);
  margin-right: 0.4rem;
}
.history-line-after {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.1)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), transparent);
  margin-left: 0.4rem;
}

.AI-Content {
  flex: 1;
  overflow: auto;
  padding: 0 1rem;
}
@keyframes chatBubbleFadeOut {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.stop-button {
  cursor: pointer;
  display: inline-block;
  background: #f5f6f9;
  border-radius: 0.25rem;
  color: var(--cosd-color-text-primary);
  font-size: var(--cos-text-body);
  line-height: 1rem;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}
.chat-item {
  -webkit-animation: chatBubbleFadeOut 0.24s ease-in-out 0ms forwards;
  animation: chatBubbleFadeOut 0.24s ease-in-out 0ms forwards;
  background: #ddd9ff;
  border-radius: 18px 3px 18px 18px;
  padding: 0.8rem 1rem;
  font-weight: 500;
  max-width: 90%;
  position: relative;
  text-align: left;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  width: -webkit-fit-content;
  width: fit-content;
}
.chat-item * {
  word-break: break-all !important;
}
.user-chat {
  background: #ddd9ff;
  margin: 0.5rem 0 0.03rem auto;
  right: 0.12rem;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  word-break: break-all !important;
}
.AI-chat {
  border-radius: 3px 18px 18px 18px;
  background: white;
  max-width: 100%;
  margin: 0.5rem 0 0.03rem;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  word-break: break-all !important;
}
.AI-Footer {
  padding: 1rem;
}
.AI-Input {
  scrollbar-color: hsla(228, 7%, 74%, 0.6) transparent;
  scrollbar-width: thin;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  align-items: center;
  background: #fff;
  border: 0.5px solid var(--cos-color-border, #b7b9c1);
  border-radius: 1.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0.5rem;
  position: relative;
}
.input-fields {
  -webkit-box-flex: 1;
  word-wrap: break-word;
  color: #1e1f24;
  color: var(--cos-color-text);
  cursor: text;
  -webkit-flex: 1;
  flex: 1;
  font-size: var(--cos-text-body-lg);
  font-size: var(--cos-text-subtitle);
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  position: relative;
  resize: none;
  text-align: left;
  -webkit-user-select: text;
  user-select: text;
  outline: none;
}

.input-content {
  min-height: 2rem;
  -webkit-box-flex: 1;
  word-wrap: break-word;
  color: #1e1f24;
  color: var(--cos-color-text);
  cursor: text;
  -webkit-flex: 1;
  flex: 1;
  font-size: var(--cos-text-body-lg);
  font-size: var(--cos-text-subtitle);
  margin: 0 3px;
  max-height: 262px;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  position: relative;
  resize: none;
  text-align: left;
  -webkit-user-select: text;
  user-select: text;
}

::v-deep .input-content .el-textarea__inner {
  width: 100%;
  overflow: hidden;
  display: block;
  border: none;
  height: auto;
  line-height: 2rem;
  font-size: 1rem;
  color: #1e1f24;
  word-wrap: break-word;
  overflow-wrap: break-word;
  resize: none;
}
.input-button {
  cursor: pointer;
  font-size: 2rem;
  line-height: 2rem;
  height: 2rem;
  color: var(--themeColor);
}
::v-deep .answer-markdown p {
  margin: 0 !important;
}
</style>
